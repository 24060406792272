class ProdEnvironment {
    signupDirect = 'https://app-signup.us.bill.com/Signup1';
    signupConsole = 'https://app-signup.us.bill.com/ConsoleSignup1';
    brInviteUrl = 'https://api.bill.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '258-FEM-750';
    directPricePlan = 'ppl01LATDULKCCIU2d88';
    signupDirectAbTest = 'https://gateway.prod.bill.com/signupservice/v1/signup';
}

class DevEnvironment extends ProdEnvironment {
    signupDirect = 'https://app-signup-test.qa.cashview.com/Signup1';
    signupConsole = 'https://app-signup-test.qa.cashview.com/ConsoleSignup1';
    brInviteUrl = 'https://app-test.cashview.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '117-IJE-550';
    directPricePlan = 'ppl01EVNBFQIHHUOWkaz';
    signupDirectAbTest = 'https://gateway.preprod.bill.com/signupservice/v1/signup';
}

class StageEnvironment extends ProdEnvironment {
    signupDirect = 'https://app-signup-stage.us.bill.com/Signup1';
    signupConsole = 'https://app-signup-stage.us.bill.com/ConsoleSignup1';
    brInviteUrl = 'https://app-stage.cashview.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '117-IJE-550';
    directPricePlan = 'ppl01EIBISMMLMKFR5vi';
    signupDirectAbTest = 'https://gateway.stage.bill.com/signupservice/v1/signup';
}

const isDev = window.location.host.includes('dev') || window.location.host.includes('test');
const isStg = isDev && window.location.href.includes('env=stage');
export const environment =  isStg ? new StageEnvironment() :
                            isDev ? new DevEnvironment() :
                                    new ProdEnvironment();