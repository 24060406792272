import { brInviteService } from "./br-invite-service";
import { isMobile } from "./device-type-detector";
import { divvyService } from "./divvy-service";
import { environment } from "./environment";
import { configureInputs, getProductInterestOrNull, toggleProductInterestError, isConsoleForm, 
         populateFromStorage, getABTestVariances, disableSubmitButton, enableSubmitButton } from "./form-service";
import { marketoService } from "./marketo-service";
import { getReferralRockEmail, submitToReferralRock } from "./referralrock-service";
import { scriptLoader } from "./script-loader";
import { storageService } from "./storage-service";
import { threatmetrixService } from "./threatmetrix-service";
import { verifyUser } from "./user-service";

declare const grecaptcha;

class SignupService {
    constructor() {}
    setupForms() {
        let recaptchaEnabled = false;
        document.querySelectorAll<HTMLFormElement>('form[data-signup-form]').forEach(form => {
            if(!recaptchaEnabled) {
                recaptchaEnabled = true;
                addRecaptchaDiv(form);
                scriptLoader.loadScript('https://www.google.com/recaptcha/api.js', { oneTrustIgnore: true });
            }

            form.onsubmit = (ev) => {
                ev.preventDefault();
                ev.stopPropagation();


                // add/validate product interest if availalbe
                const productInterest = getProductInterestOrNull(form);
                
                if (productInterest !== null) {
                    if (productInterest.length) {
                        addHiddenField(form, 'productInterest', productInterest);
                    } else {
                        toggleProductInterestError(form, true);
                        return;
                    }
                }

                disableSubmitButton(ev.submitter);

                window['bdcSubmit'] = {form, submitter: ev.submitter};
                grecaptcha.execute();
            };

            configureInputs(form);
            populateFromStorage(form);
            brInviteService.showDialogOnEmailEntry(form);
            marketoService.loadForm(); //preload marketo form            
            if(!threatmetrixService.isDeviceRegistered()) threatmetrixService.registerDevice();
        });
    }
}

//exposing for recaptcha to call it
window['verifyEmail'] = function(token: string) {
    grecaptcha.reset();
    const form: HTMLFormElement = window['bdcSubmit'].form;
    const submitter: HTMLInputElement = window['bdcSubmit'].submitter;
    const email = (form.elements.namedItem('email') as any).value;

    return verifyUser({email})
        .then(v =>  v.success ? Promise.resolve() : Promise.reject(v.email || 'Unexpected error'))
        .then(() => submitForm(form, token, submitter))
        .catch(err => showError(err, form, submitter));
}

function submitForm(form: HTMLFormElement, token: string, submitter: HTMLInputElement ) {
    const queryParams = new URLSearchParams(window.location.search);
    const origin = window.location.origin;
    const url = origin + window.location.pathname;
    const isConsole = isConsoleForm(form);

    fillUtmTracking(form);
    addHiddenField(form, '__SFDC_Org_Signup_URL__c' , url);
    addHiddenField(form, '__SFDC_Org_Device_Type__c', isMobile() ? 'm' : 'c');

    const referral = queryParams.get('referral') || form.dataset.referral;
    if (referral) addHiddenField(form, 'referral', referral);

    const acReferClients = form.querySelector<HTMLInputElement>('#acReferClients');
    if(acReferClients?.checked) {
        addHiddenField(form, '__SFDC_Org_AB_Test_Slot_1__c', 'AC Referral Program')
            .setAttribute('data-mkto-field', 'AB_Test_Slot_1__c');
    }

    const abVals = getABTestVariances();
    if (abVals.length) addHiddenField(form, '__SFDC_Org_AB_Test_2__c', abVals);

    const affiliateId = storageService.getLastTouchValues()?.affiliateId;
    if(affiliateId) addHiddenField(form, 'bdc_affiliate', affiliateId);

    if (isConsole) {
        form.action = form.hasAttribute('action') ? form.action : environment.signupConsole;

        let defaultConsolePricePlan = 'ppl01BZCWSZDQCQB1nb5';
        const isWealthManagement = form.querySelector<HTMLInputElement>('input#firmTypeWealthManagement')?.checked;
        if(isWealthManagement) {
            addHiddenField(form, 'WealthManagement', 'TRUE');
            defaultConsolePricePlan = 'ppl01FMLYJIDVQNE1I76';
        }

        addHiddenField(form, 'formoptions', 'console');
        addHiddenField(form, 'confirmUrl', origin + (form.dataset.redirect || '/console-confirmation'));
        addHiddenField(form, 'consolepriceplan', queryParams.get('consolepriceplan')
                                                    || storageService.getConsolePricePlan()
                                                    || form.dataset.consolepriceplan 
                                                    || defaultConsolePricePlan);
        
        const mngClientsBillPay = form.querySelector<HTMLInputElement>('#mngClientsBillPay');
        if(acReferClients?.checked && !mngClientsBillPay?.checked) {
            submitToReferralRock(form).then(err => showError(err, form, submitter));
            return;
        } 

    } else {
        form.action = form.hasAttribute('action') ? form.action : environment.signupDirect;
        addHiddenField(form, 'confirmUrl', origin + (form.dataset.redirect || '/confirmation'));
        addHiddenField(form, 'priceplan', queryParams.get('priceplan') || form.dataset.priceplan || 'ppl01WHWMQXSURQX2dgf');
    }

    const isSpendAndExpenseOnly = form.elements['productInterest']?.value === "SE";
    const sneSubmit = form.dataset.sneSubmit; // as of 7/12/2024 netsuite-signup wants to submit SE to product.
    if(!isConsole && isSpendAndExpenseOnly && sneSubmit !== 'signup') {
        divvyService.submitSpendAndExpenseForm(form);
        return;
    }

    const referralRockCode = storageService.getReferralRockCode();
    let referralRockEmail$ = Promise.resolve() as Promise<any>;
    if (referralRockCode) {
        addHiddenField(form, '__SFDC_Org_orgReferral_name__c', 'referral program');
        addHiddenField(form, '__SFDC_Org_PartnerStack_Customer_Key__c', referralRockCode)
            .setAttribute('data-mkto-field', 'PartnerStack_Customer_Key__c'); // this value get synced over by marketo
        referralRockEmail$ = getReferralRockEmail(new FormData(form), referralRockCode)
                                // transform to marketo key/value
                                .then(email => ({ 'ReferralRock_Referring_Partner_Email': email }))
                                .catch(console.error);
    }

    addRecaptchaToken(form, token);

    // the catch ignores all errors that happen in this chain e.g. marketo since we don't want to block posting to product
    const marketo$ = Promise.all([marketoService.loadForm(), referralRockEmail$])
                        .then(([mktoForm, referralRockEmail]) => mktoForm.submit(form, referralRockEmail))
                        .catch(console.error);
    
    threatmetrixService.sessionId?.then(sid => addHiddenField(form, 'tmxSessionId', sid));
    
    marketo$.then(() => postForm(form, form.action));
    return false;
}

function addRecaptchaToken(form: HTMLFormElement, token: string) {
    const tokenField = form.querySelector<HTMLTextAreaElement>('#g-recaptcha-response');
    if(tokenField) tokenField.value = token;
    else addHiddenField(form, 'g-recaptcha-response', token);
}

function showError(message: string | undefined, form: HTMLFormElement, submit: HTMLElement) {
    if(!message) return;
    enableSubmitButton(submit);
    $(form).nextAll('.w-form-fail').show().children().text(message);
}

function addRecaptchaDiv(form:HTMLFormElement) {
    const div = document.createElement('div');
    div.setAttribute('data-sitekey', environment.recaptchaKey);
    div.setAttribute('data-size', 'invisible');
    div.setAttribute('data-callback', 'verifyEmail');
    div.setAttribute('class', 'g-recaptcha');
    form.insertAdjacentElement('beforeend', div);
}

function postForm(form: HTMLFormElement, url: string): void {    
    const newForm = window.document.createElement('form');
    document.body.appendChild(newForm);
    newForm.setAttribute('method', 'post');
    newForm.setAttribute('action', url);
    newForm.setAttribute('target', '_self');

    new FormData(form).forEach((v, k) => addHiddenField(newForm, k, v.toString()))

    updateMsDynamics(newForm);
    formOptionsToFirmType(newForm);
    
    newForm.submit();
}

function updateMsDynamics(form: HTMLFormElement) {    
    const accountingSoftware = form.elements.namedItem('accountingSoftware') as HTMLInputElement;   
    const msDynamics = form.elements.namedItem('msDynamicsOption') as HTMLInputElement;
    //override accounting software value with selection from ms dynamics dropdown
    if(accountingSoftware?.value === 'msdynamics' && msDynamics)
        accountingSoftware.value = msDynamics.value;
}

function fillUtmTracking(form: HTMLFormElement) {
    const vals = storageService.getFirstTouchValues();
    const clickIds = storageService.getClickIds();
    if (clickIds?.gclid) { addHiddenField(form, '__SFDC_Org_GCLID__c', clickIds.gclid); }
    if (vals.ppcKeyword) { addHiddenField(form, '__SFDC_Org_PPC_content__c', vals.ppcKeyword); }
    if (vals.device) { addHiddenField(form, '__SFDC_Org_PPC_network__c', vals.device); }
    if (vals.ppcPlacement) { addHiddenField(form, '__SFDC_Org_Trial_Program_Description__c', vals.ppcPlacement); }
    if (vals.trafficType) { addHiddenField(form, '__SFDC_Org_Traffic_Type__c', vals.trafficType); }
    if (vals.utmCampaign) { addHiddenField(form, '__SFDC_Org_PPC_campaign__c', vals.utmCampaign); }
    if (vals.utmContent) { addHiddenField(form, '__SFDC_Org_PPC_content__c', vals.utmContent); }
    if (vals.utmMedium) { addHiddenField(form, '__SFDC_Org_Trial_Campaign__c', vals.utmMedium); }
    if (vals.utmSource) { addHiddenField(form, '__SFDC_Org_Trial_Program__c', vals.utmSource); }
    if (vals.utmTerm) { addHiddenField(form, '__SFDC_Org_PPC_term__c', vals.utmTerm); }
}

function addHiddenField(form: HTMLFormElement, name: string, value: string): HTMLInputElement {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    form.appendChild(input);
    return input;
}

//hack to allow wmanagement (firmtype) in formoptions radio group
function formOptionsToFirmType(form: HTMLFormElement) {
    const el = form.querySelector<HTMLInputElement>('input[name=formoptions][value=wmanagement]');
    if(el) el.name = 'firmtype'
}

export const signupService = new SignupService();