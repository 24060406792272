import { componentService } from "./modules/component-service";
import { cookieService } from "./modules/cookie-service";
import "./modules/core-js";
import { divvyService } from "./modules/divvy-service";
import { configureLoginButton } from "./modules/login-button";
import { marketoService } from "./modules/marketo-service";
import { signupService } from "./modules/signup-service";
import { storageService } from "./modules/storage-service";
import { captureUtms } from "./modules/utm-service";
import { onReady } from "./modules/window-service";

captureUtms();
onReady(() => {
    console.log('loaded');
    marketoService.setupForms();
    signupService.setupForms();
    configureLoginButton();
    componentService.configureAll();
    divvyService.configureAll();
    //TEMP onelogin toggle based on cookie value
    const ol = cookieService.getOneLogin();
    if(ol === '1') document.querySelectorAll('.supernav-menu-wrapper').forEach(el => el.classList.toggle('hide'));
});

window.addEventListener('unload', () => storageService.setPreviousPage(window.location.href));