import { configureInputs, disableSubmitButton, populateFromStorage, getNumberOfEmployees } from "./form-service";
import { marketoService } from "./marketo-service";
import { storageService } from "./storage-service";

class DivvyService {
    configureAll() {
        this.setupSpendAndExpenseForms();
        this.addUtmsToLinks();
    }

    submitSpendAndExpenseForm(form: HTMLFormElement): Promise<any> {
        return marketoService.loadForm()
            .then(mkto => mkto.submit(form, {op_lt_product_interest: 'SE'}))
            .then(() => {
                const em = form.querySelector<HTMLInputElement>('[name=email]');
                if (em?.value) {
                    storageService.setBdcEmailForDivvy(em.value);
                }
            })
            .then(() => {
                const num = getNumberOfEmployees(form) || 1;
                let thankYouSmb = '/thankyou/demo-video';
                if(form.dataset.thankYouSmb?.length) thankYouSmb = form.dataset.thankYouSmb;
                window.location.href = num < 21 ? thankYouSmb : '/thankyou';
            });
    }

    private setupSpendAndExpenseForms() {
        document.querySelectorAll<HTMLFormElement>('form[data-sne-form]').forEach(form => {
            configureInputs(form);
            populateFromStorage(form);

            form.onsubmit = ev => {
                ev.preventDefault();
                ev.stopPropagation();
                
                disableSubmitButton(ev.submitter);
                
                this.submitSpendAndExpenseForm(form);
                return false;
            };
        });
        marketoService.loadForm(); //preload marketo form
    }

    private addUtmsToLinks() {
        let utms:string;
        document.querySelectorAll<HTMLAnchorElement>("a[href^='https://apply.divvy.co']").forEach(a => {
            utms = utms || this.encodeUtmValues();
            a.href = "https://apply.divvy.co/?" + utms;
        });
    }

    private encodeUtmValues() {
        const utms = {
            lt: storageService.getLastTouchValues(),
            ft: storageService.getFirstTouchValues(),
            clickIds: storageService.getClickIds(),
            previousPage: storageService.getPreviousPage(),
            isFirstTouch: storageService.getIsFirstTouchSession()
        };

        // add email 
        const email = storageService.getBdcEmailForDivvy();
        if (email) {
            utms['email'] = email;
        }

        // filter out all empty values
        // create an array of single key objects
        const params = Object.keys(utms)
                        .filter(key => Boolean(utms[key]))
                        .map(key => ({[key]: utms[key]}));
        // const ec = window.btoa(JSON.stringify(params));
        // console.log('encode', ec);
        // console.log('decode:', window.atob(ec));
        return window.btoa(JSON.stringify(params));
    }

}

export const divvyService = new DivvyService();